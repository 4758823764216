/* after changing this file run 'npm run webapp:build' */

/***************************
put Sass variables here:
eg $input-color: red;
****************************/

// Override Boostrap variables
$action: #6c6c6c !default;
$action-green: #74d878 !default;
$silent-grey: #dedede !default;

// Import Bootstrap source files from node_modules
@import '~bootswatch/dist/flatly/variables';
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-vue/src/index.scss';
@import '~bootswatch/dist/flatly/bootswatch';
/* Dirty fix on navbar active link with bootswatch theme */
nav li.router-link-active .navbar-dropdown-menu {
  color: $navbar-dark-active-color;
}

.poppins {
  font-family: 'Poppins', sans-serif;
}

.btn-action {
  color: $action;
  background: none;
  border: 0;
  @include box-shadow($btn-box-shadow);

  @include hover() {
    color: darken($action, 15%);
    background: lighten($action, 40%);
  }

  &:focus,
  &.focus {
    color: darken($action, 15%);
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    color: lighten($action, 15%);
  }
}

.btn-action-green {
  background: white;
  color: $action-green;
  border: 0;

  @include box-shadow($btn-box-shadow);

  @include hover() {
    color: white;
    background: $action-green;
  }

  &:focus,
  &.focus {
    color: darken($action-green, 15%);
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    color: lighten($action-green, 15%);
  }
}

.bg-silent-grey {
  background: $silent-grey !important;
}

a {
  color: $primary;
  @include hover() {
    color: darken($primary, 10%);
  }
}

.card-footer:last-child {
  border-radius: 0 0 calc(1rem - 1px) calc(1rem - 1px);
}

.card {
  border-radius: 1rem;
}

.project-card:hover {
  @extend .shadow;
  transform: translateY(-0.5rem);
  z-index: 1;
}
.btn:focus,
.btn.focus,
.btn:focus-visible,
.btn:focus-visible span {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
/* jhipster-needle-scss-add-vendor JHipster will add new css style */
